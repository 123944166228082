<template>
  <div class="lesson__container">
    <div class="d-flex flex-row justify-content-between my-3 px-2">
      <div class="d-flex flex-row align-items-baseline">
        <font-awesome-icon icon="circle" class="fa-xs mr-2 icon_color" />
        <h4 class="font-weight-bold ft-s-18">เนื้อหาการสอน</h4>
        <small class="text-black-50 ml-2 font-weight-bold"
          >จำนวน
          {{ formLessons && formLessons.length ? formLessons.length : 0 }}
          บท</small
        >
      </div>
      <!-- <b-button
        v-if="isLesson"
        size="sm"
        type="submit"
        variant="primary"
        :disabled="disabled"
        @click.prevent="addLesson"
        >เพิ่ม</b-button
      > -->
    </div>

    <b-card
      v-for="(formLesson, index) in formLessons"
      :key="`formLesson__${index}`"
      :ref="`formLesson__${index}`"
      class="mb-3"
      header-class="card__header"
    >
      <template #header>
        <div class="d-flex flex-row justify-content-between mt-2">
          <h6 style="font-weight: 700">
            บทที่ {{ formLesson.lesson_number }}. {{ formLesson.lesson_name }}
          </h6>
          <!-- <font-awesome-icon
            v-show="!isClass"
            icon="window-close"
            class="fa-lg cursor-pointer"
            @click.prevent="deleteLesson(index)"
          /> -->
        </div>
      </template>
      <div class="d-flex flex-row flex-wrap mb-2">
        <!-- <div class="col-lg-4 col-12 px-2 mb-2">
          <label class="font-weight-bold ft-s-14" for="box6">ชื่อบท</label>

          <input
            class="form-control custom__height"
            type="text"
            disabled
            v-model="formLesson.lesson_name"
          />
        </div> -->
        <div class="col-lg-4 col-12 px-2 mb-2">
          <label class="font-weight-bold ft-s-14" for="lessonDate"
            >วันที่เรียน</label
          >
          <datepicker
            ref="datepicker"
            id="lessonDate"
            input-class="form-control"
            required
            :language="th"
            :format="formatBuddhistBirthDate"
            :disabled="disabled"
            v-model="lessonDate[index]"
            @selected="onLessonDateChanged($event, index)"
          ></datepicker>
        </div>
        <div class="col-lg-6 col-12 px-2 mb-2">
          <label class="font-weight-bold ft-s-14" for="box8">เวลาเรียน</label>
          <div class="d-flex flex-row">
            <v-select
              class="mx-2"
              id="lesson_start_hrs"
              :options="isHrs"
              v-model="startHr[index]"
              :disabled="disabledTime || disabled"
              style="width: 100px"
              placeholder="ชั่วโมง"
              @input="setTimeLesson($event, index)"
            ></v-select>
            <span class="d-flex flex-column justify-content-around"
              ><b>:</b></span
            >
            <v-select
              class="mx-2"
              id="lesson_start_mins"
              :options="isMins"
              v-model="startMin[index]"
              :disabled="disabledTime || disabled"
              style="width: 100px"
              placeholder="นาที"
              @input="setTimeLesson($event, index)"
            ></v-select>
            <span class="d-flex flex-column justify-content-around">ถึง</span>
            <v-select
              class="mx-2"
              id="lesson_end_hrs"
              :options="isHrs"
              v-model="endHr[index]"
              :disabled="true"
              style="width: 100px"
              placeholder="ชั่วโมง"
              @input="setTimeLesson($event, index)"
            ></v-select>
            <span class="d-flex flex-column justify-content-around"
              ><b>:</b></span
            >
            <v-select
              class="mx-2"
              id="lesson_start_mins"
              :options="isMins"
              v-model="endMin[index]"
              :disabled="true"
              style="width: 100px"
              placeholder="นาที"
              @input="setTimeLesson($event, index)"
            ></v-select>
          </div>

          <!-- <input
            class="form-control custom__height"
            type="text"
            placeholder="ชั่วโมง:นาที-ชั่วโมง:นาที"
            :disabled="disabled || isClass"
            v-mask="timeRangeMask"
            v-model="formLesson.time"
          /> -->
        </div>
        <div
          class="
            d-flex
            flex-column
            justify-content-end
            col-lg-2 col-12
            px-2
            mb-2
            pb-1
          "
        >
          <span class="text-black-50">{{ totalTime(formLesson.time) }}</span>
        </div>
      </div>

      <div class="d-flex flex-row">
        <div class="col-12 px-2">
          <label class="font-weight-bold ft-s-14">เนื้อหา</label>

          <div
            contenteditable="false"
            class="border border-medium rounded pre-line p-3"
            v-html="formLesson.content"
          ></div>
          <!-- <quill-editor
            ref="contentEditor"
            class="content-editor"
            :class="{ 'is-class': isClass }"
            :disabled="disabled || isClass"
            v-model="formLesson.content"
            rows="4"
            :options="
              isClass
                ? {
                    modules: {
                      toolbar: [],
                    },
                    placeholder: 'รายละเอียด',
                    readOnly: true,
                  }
                : editorOption
            "
          >
          </quill-editor> -->
        </div>
      </div>

      <div v-if="isClass" class="d-flex flex-row mt-2">
        <div class="col-12 px-2">
          <label class="font-weight-bold ft-s-14" for="note">หมายเหตุ</label>

          <b-textarea
            name="note"
            id="note"
            :disabled="disabled"
            v-model="formLesson.note"
            :state="validataNote(formLesson.note)"
          >
          </b-textarea>
          <label
            v-if="validataNote(formLesson.note) == false"
            class="mt-2 ft-s-14 text-danger"
            for="note"
          >กรุณาใส่หมายเหตุไม่เกิน 350 ตัวอักษร</label>
          <!-- <quill-editor
            :disabled="disabled"
            ref="reMarkEditor"
            v-model="formLesson.note"
            :options="editorOption"
          >
          </quill-editor> -->
        </div>
      </div>

      <!-- <div v-if="isClass">
        <div class="d-flex flex-row my-3">
          <div class="col-12 px-2">
            <label class="font-weight-bold ft-s-14" for="box9"
              >รูปหลักฐานการสอน (ไม่เกิน 2 รูป)</label
            >
            <b-button
              size="sm"
              variant="primary"
              class="mx-2"
              :disabled="disabled"
              @click="toggleAttachFile(index)"
            >
              เลือกไฟล์
            </b-button>
            <small class="text-black-50 ml-2 font-weight-bold"
              >*JPG, PNG ขนาดไม่เกิน 15MB (ขนาดแนะนำ {{ imageWidth }} x
              {{ imageHeight }} px)</small
            >

            <small class="text-danger ml-2 font-weight-bold"
              >*กรณีที่รูปผิด ท่านสามารถลบรูปและเลือกรูปใหม่ และกดบันทึก</small
            >
            //<small class="ml-2 font-weight-bold" style="color: #f83245"
              >*กำลังพัฒนา</small
            >//
            <input
              v-show="false"
              type="file"
              :id="`lession-file-${index}`"
              name="addLessonPlanImg"
              accept="image/*"
              multiple
              ref="addLessonPlanImg"
              @change="previewImages($event, index)"
            />
          </div>
        </div>

        //<div
          class="image-preview d-flex flex-row-warp"
          style="flex-wrap: wrap"
          v-if="
            formLesson &&
              formLesson.teaching_actual_imgs &&
              formLesson.teaching_actual_imgs.length > 0
          "
        >
          <div
            v-for="imageData in formLesson.teaching_actual_imgs"
            :key="imageData"
            class="box-preview"
          >
            <img :src="imageData" />
          </div>
        </div>//

        <carousel-teaching-image
          :removable="!disabled"
          :is-loading="disabled"
          v-model="formLesson.teaching_actual_imgs"
        >
          <div class="d-flex align-items-center">
            <b-spinner
              v-if="isProcessing"
              label="processing image..."
              class="ml-4 mb-4"
            >
            </b-spinner>
          </div>
        </carousel-teaching-image>
      </div> -->
      <slot name="custom-body"></slot>
    </b-card>
  </div>
</template>

<script>
// import "quill/dist/quill.core.css";
// import "quill/dist/quill.snow.css";
// import "quill/dist/quill.bubble.css";
// import { quillEditor } from "vue-quill-editor";
// import Compressor from "compressorjs";
import { th } from "vuejs-datepicker/dist/locale";
// import CarouselTeachingImage from "../carousel/TeachingImage";
import Datepicker from "vuejs-datepicker";
import { formatBuddhistBirthDate } from "../../helpers/datepicker-helper";
import vSelect from "vue-select";

export default {
  components: {
    vSelect,
    // quillEditor,
    // CarouselTeachingImage,
    Datepicker,
  },
  props: {
    data: Array,
    isLesson: {
      type: Boolean,
      default: false,
    },
    isClass: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    disabledTime: {
      type: Boolean,
      default: false,
    },

    imageWidth: {
      type: Number,
      default: 1920,
    },

    imageHeight: {
      type: Number,
      default: 1080,
    },
  },

  model: {
    prop: "data",
    event: "change",
  },

  data() {
    return {
      isProcessing: false,
      formLessons: [...this.data],
      maxFiles: 2,
      startHr: this.data
        ? this.data.reduce((acc, element) => {
            let times = element.time.split("-");
            if (times[0]) {
              let startTime = times[0].split(":");
              acc.push(startTime[0] ? startTime[0] : null);
            } else {
              acc.push(null);
            }
            return acc;
          }, [])
        : [],
      startMin: this.data
        ? this.data.reduce((acc, element) => {
            let times = element.time.split("-");
            if (times[0]) {
              let startTime = times[0].split(":");
              acc.push(startTime[1] ? startTime[1] : null);
            } else {
              acc.push(null);
            }
            return acc;
          }, [])
        : [],
      endHr: this.data
        ? this.data.reduce((acc, element) => {
            let times = element.time.split("-");
            if (times[1]) {
              let endTime = times[1].split(":");
              acc.push(endTime[0] ? endTime[0] : null);
            } else {
              acc.push(null);
            }
            return acc;
          }, [])
        : [],
      endMin: this.data
        ? this.data.reduce((acc, element) => {
            let times = element.time.split("-");
            if (times[1]) {
              let endTime = times[1].split(":");
              acc.push(endTime[1] ? endTime[1] : null);
            } else {
              acc.push(null);
            }
            return acc;
          }, [])
        : [],
      isHrs: [
        "00",
        "01",
        "02",
        "03",
        "04",
        "05",
        "06",
        "07",
        "08",
        "09",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
      ],
      // isMins: [
      //   "00",
      //   "01",
      //   "02",
      //   "03",
      //   "04",
      //   "05",
      //   "06",
      //   "07",
      //   "08",
      //   "09",
      //   "10",
      //   "11",
      //   "12",
      //   "13",
      //   "14",
      //   "15",
      //   "16",
      //   "17",
      //   "18",
      //   "19",
      //   "20",
      //   "21",
      //   "22",
      //   "23",
      //   "24",
      //   "25",
      //   "26",
      //   "27",
      //   "28",
      //   "29",
      //   "30",
      //   "31",
      //   "32",
      //   "33",
      //   "34",
      //   "35",
      //   "36",
      //   "37",
      //   "38",
      //   "39",
      //   "40",
      //   "41",
      //   "42",
      //   "43",
      //   "44",
      //   "45",
      //   "46",
      //   "47",
      //   "48",
      //   "49",
      //   "50",
      //   "51",
      //   "52",
      //   "53",
      //   "54",
      //   "55",
      //   "56",
      //   "57",
      //   "58",
      //   "59",
      // ],
      isMins: [
        "00",
        "30",
      ],
      // editorOption: {
      //   modules: {
      //     toolbar: [
      //       ["bold", "italic", "underline", "strike"],
      //       ["blockquote", "code-block"],
      //       [{ header: 1 }, { header: 2 }],
      //       [{ list: "ordered" }, { list: "bullet" }],
      //       [{ indent: "-1" }, { indent: "+1" }],
      //       [{ direction: "rtl" }],
      //       [{ size: ["small", false, "large", "huge"] }],
      //       [{ header: [1, 2, 3, 4, 5, 6, false] }],
      //       [{ font: [] }],
      //       [{ color: [] }, { background: [] }],
      //       [{ align: [] }],
      //       ["clean"],
      //     ],
      //   },
      //   placeholder: "รายละเอียด",
      // },
      th: th,
      lessonDate: this.data
        ? this.data.reduce((acc, element) => {
            acc.push(
              element.date
                ? new Date(element.date).setFullYear(
                    new Date(element.date).getFullYear()
                  )
                : null
            );
            return acc;
          }, [])
        : [],
    };
  },

  watch: {
    formLessons: {
      deep: true,
      handler: "syncData",
    },

    // lessonDate: {
    //   handler: "onLessonDateChanged",
    // },

    // data: {
    //   immediate: true,
    //   handler: "syncDateData",
    // },
  },

  methods: {
    formatBuddhistBirthDate,

    setTimeLesson(event, index) {
      // if (event == null) {
      //   return (this.formLessons[index].time = "");
      // }
      if (
        this.startHr[index] == null &&
        this.startMin[index] == null
        //  && this.endHr[index] == null &&
        // this.endMin[index] == null
      ) {
        this.endHr[index] = null
        this.endMin[index] = null
        this.formLessons[index].time = "";
      } else if (
        this.startHr[index] !== null &&
        this.startMin[index] !== null
      ) {
        this.endHr[index] = String(parseInt(this.startHr[index]) + 1).padStart(2, '0')
        this.endMin[index] = this.startMin[index]
        this.formLessons[
          index
        ].time = `${this.startHr[index]}:${this.startMin[index]}-${this.endHr[index]}:${this.endMin[index]}`;
      }
      // else {
      //   this.formLessons[
      //     index
      //   ].time = `${this.startHr[index]}:${this.startMin[index]}-${this.endHr[index]}:${this.endMin[index]}`;
      // }
    },

    timeMask(value) {
      const hours = [/[0-2]/, value.charAt(0) === "2" ? /[0-3]/ : /[0-9]/];
      const minutes = [/[0-5]/, /[0-9]/];
      return value.length > 2 ? [...hours, ":", ...minutes] : hours;
    },
    timeRangeMask(value) {
      const numbers = value.replace(/[^0-9]/g, "");
      if (numbers.length > 4) {
        return [
          ...this.timeMask(numbers.substring(0, 4)),
          "-",
          ...this.timeMask(numbers.substring(4)),
        ];
      }
      return [...this.timeMask(numbers)];
    },

    // toggleAttachFile(i) {
    //   if (this.formLessons[i]) {
    //     const { teaching_actual_imgs = [] } = this.formLessons[i];

    //     if (teaching_actual_imgs.length >= this.maxFiles) {
    //       return this.$toast.error(
    //         `ไม่สามารถแนบหลักฐานการสอนบทที่ ${i + 1}. ได้เกิน ${
    //           this.maxFiles
    //         } รูป`
    //       );
    //     }

    //     this.$nextTick(() => {
    //       const { addLessonPlanImg } = this.$refs;

    //       if (addLessonPlanImg[i]) {
    //         addLessonPlanImg[i].click();
    //       }
    //     });
    //   }
    // },

    addLesson() {
      this.formLessons.push({
        lesson_name: "",
        date: "",
        time: "",
        content: "",
        note: "",
        // teaching_actual_imgs: [],
      });

      this.$nextTick(() => {
        const latestIndex = this.formLessons.length - 1;
        const latestLesson = this.$refs[`formLesson__${latestIndex}`];

        if (latestLesson && latestLesson.length) {
          latestLesson[0].scrollIntoView({
            behavior: "smooth",
            block: "end",
            inline: "nearest",
          });
        }
      });
    },

    deleteLesson(index) {
      this.formLessons.splice(index, 1);
    },

    syncData(v) {
      this.$emit("change", v);
    },

    syncDateData(v) {
      if (v.length == 0) return;
      for (let index = 0; index < v.length; index++) {
        const element = v[index].date;
        if (element) {
          this.lessonDate[index] = new Date(element).setFullYear(
            new Date(element).getFullYear()
          );
        }
      }
    },

    // async previewImages(event, index) {
    //   let { maxFiles } = this;

    //   if (this.formLessons[index]) {
    //     const { teaching_actual_imgs = [] } = this.formLessons[index];

    //     if (teaching_actual_imgs.length < this.maxFiles) {
    //       maxFiles -= teaching_actual_imgs.length;
    //     }
    //   }

    //   if (maxFiles <= 0) {
    //     return;
    //   }

    //   const self = this;

    //   const { imageWidth, imageHeight } = this;

    //   var input = event.target;

    //   const maxAllowedSize = 15 * 1024 * 1024;

    //   this.isProcessing = true;

    //   let fileCounter = 0;

    //   Array.from(input.files)
    //     .slice(0, maxFiles)
    //     .forEach((file) => {
    //       if (file.size <= maxAllowedSize) {
    //         fileCounter++;

    //         new Compressor(file, {
    //           quality: 0.8,

    //           maxWidth: imageWidth,

    //           maxHeight: imageHeight,

    //           // The compression process is asynchronous,
    //           // which means you have to access the `result` in the `success` hook function.
    //           success(result) {
    //             fileCounter -= 1;

    //             var reader = new FileReader();

    //             reader.onload = async (e) => {
    //               self.formLessons[index].teaching_actual_imgs.push({
    //                 file: result,
    //                 raw: e.target.result,
    //               });

    //               if (fileCounter === 0) {
    //                 self.isProcessing = false;
    //               }
    //             };

    //             reader.readAsDataURL(result);
    //           },
    //           error(err) {
    //             console.log(err.message);
    //             this.$toast.error(
    //               "ไม่สามารถอัพโหลดไฟล์ได้ กรุณาลองใหม่อีกครั้ง"
    //             );
    //           },
    //         });

    //         // var reader = new FileReader();

    //         // reader.onload = async (e) => {
    //         //   this.formLessons[index].teaching_actual_imgs.push({
    //         //     file,
    //         //     raw: e.target.result,
    //         //   });
    //         // };

    //         // reader.readAsDataURL(file);
    //       } else {
    //         this.$toast.error(
    //           "ไม่สามารถอัพโหลดไฟล์ขนาดใหญ่เกิน 15 MB ได้ กรุณาลองใหม่อีกครั้ง"
    //         );
    //       }
    //     });

    //   if (fileCounter === 0) {
    //     this.isProcessing = false;
    //   }

    //   input.value = "";

    //   // if (
    //   //   input.files &&
    //   //   input.files[0] &&
    //   //   input.files[0].size > maxAllowedSize
    //   // ) {
    //   //   input.value = "";
    //   // } else {
    //   //   var reader = new FileReader();
    //   //   reader.onload = async (e) => {
    //   //     this.formLessons[index].teaching_actual_imgs.push(e.target.result);
    //   //   };
    //   //   reader.readAsDataURL(input.files[0]);
    //   // }
    // },

    totalTime(time) {
      if (time.length !== 11) return "--ชั่วโมง --นาที";

      if (time) {
        let lessonTime = time.split("-");

        let [h1, m1] = lessonTime[0].split(":");
        let [h2, m2] = lessonTime[1].split(":");

        let totalHr;
        let totalMin;
        let totalHrs;
        let totalMins;

        if (h2 >= h1) {
          totalHr = parseInt(h2) - parseInt(h1);
        } else {
          totalHr = 24 + parseInt(h2) - parseInt(h1);
        }

        if (m2 >= m1) {
          totalMin = parseInt(m2) - parseInt(m1);
        } else {
          totalMin = 60 + parseInt(m2) - parseInt(m1);
          totalHr = totalHr - 1;
        }

        // if (totalHr < 10) {
        //   totalHrs = "0" + totalHr.toString();
        // } else {
        //   totalHrs = totalHr.toString();
        // }

        // if (totalMin < 10) {
        //   totalMins = "0" + totalMin.toString();
        // } else {
        //   totalMins = totalMin.toString();
        // }

        totalHrs = totalHr.toString();
        totalMins = totalMin.toString();

        return `${totalHrs.toString()}ชั่วโมง ${totalMins.toString()}นาที`;
      }
    },

    onLessonDateChanged(date, index) {
      if (date) {
        const dObj = new Date(date.getTime());
        // dObj.setFullYear(dObj.getFullYear() + 543);
        dObj.setHours(0);
        dObj.setMinutes(0);
        dObj.setSeconds(0);
        this.$set(this.formLessons[index], "date", dObj.toISOString());
      }
    },

    validataNote(note) {
      // if (note == "") return null
      // else if (note !== "" && note.length <= 350) return true
      // else return false
      if (note !== "" && note.length > 350) return false
      else return null
    }
  }
};
</script>

<style lang="scss">
.content-editor {
  &.is-class {
    .ql-toolbar {
      display: none;
    }

    .ql-container {
      border-top: 1px solid #ccc !important;
    }
  }
}
</style>
